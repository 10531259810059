<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
            <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                <b-button class="text-right" @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button>
            </b-col>
        </b-row>
                <b-overlay :show="unitLoad">
        <iq-card>
          <template v-slot:body>
            <b-row>
                   <b-col sm="12" md="12" lg="12" xl="12">
                      <div style="font-size:18px; background-color: #386389;">
                          <h5 class="text-white text-center">
                              {{ `${$t('research_manage.team_member')} ${$t('globalTrans.details')}` }}
                          </h5>
                      </div>
                    </b-col>
                    <b-col v-if="userList.length > 0" md="12" class="table-responsive">
                      <b-table-simple striped bordered small hover>
                          <tbody>
                              <b-tr>
                                  <b-th >{{ $t('research_manage.team_name') }}</b-th>
                                  <b-td>{{ getMonitoringTeam(teamMembers.team_id) }}</b-td>
                                  <b-th >{{ $t('research_manage.organization_name') }}</b-th>
                                  <b-td>{{ getOrgProfileList(teamMembers.org_id) }}</b-td>
                                  <b-th >{{ $t('globalTrans.fiscal_year') }}</b-th>
                                  <b-td>{{ getFiscalYear(teamMembers.fiscal_year_id) }}</b-td>
                              </b-tr>
                          </tbody>
                      </b-table-simple>
                      <b-table-simple striped bordered small hover>
                        <thead>
                            <tr>
                                <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                <th class="text-center">{{ $t('globalTrans.organization' )}}</th>
                                <th class="text-center">{{ $t('research_manage.designation_en') }}</th>
                                <th class="text-center">{{ $t('research_manage.team_member') }}</th>
                                <th class="text-center">{{ $t('research_manage.role_name') }}</th>
                                <th class="text-center">{{ $t('globalUserData.mobile_no') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <b-tr v-for="(item, index) in teamMembers.members" :key="item.user_id">
                              <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                <b-td class="text-center">{{ getOrgName(item.org_id) }}</b-td>
                                <b-td class="text-center">{{ getDesignationName(item.designation_id) }}</b-td>
                                <b-td class="text-center">{{ getTeamMember(item.user_id) }}</b-td>
                                <b-td class="text-center">{{ getRoleName(item.master_role_id) }}</b-td>
                                <b-td class="text-center">{{ item.mobile_no | mobileNumber }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    </b-col>
            </b-row>
          </template>
        </iq-card>
                </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringTeamMembersShowApi } from '../../../api/routes'
import ExportPdf from './export_pdf_details'

export default {
  props: ['id'],
  components: {
  },
  created () {
      const tmp = this.item
      this.teamMembers = tmp
      this.getLoadData()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        teamMembers: {},
        userList: [],
        unitLoad: false
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    orgList: function () {
        const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
        return objectData.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    }
  },
  methods: {
    async getLoadData () {
      this.unitLoad = true
       const result = await RestApi.getData(agriResearchServiceBaseUrl, `${monitoringTeamMembersShowApi}/${this.id}`)
        if (result.success) {
          this.teamMembers = result.data.monitoring_team_members
          this.userList = result.data.user_details
          this.unitLoad = false
        } else {
          this.teamMembers = {}
          this.unitLoad = false
        }
    },
     getTeamMember (userId) {
       const user = this.userList.find(item => item.value === parseInt(userId))
       if (user === undefined) return ''
       return this.$i18n.locale === 'bn' ? user.text_bn : user.text_en
     },
     getOrgName (orgId) {
      const orgName = this.$store.state.orgList.find(item => item.value === orgId)
      if (orgName === undefined) return ''
      return this.$i18n.locale === 'bn' ? orgName.text_bn : orgName.text_en
     },
      getMonitoringTeam (monitoringId) {
       const team = this.$store.state.AgriResearch.commonObj.monitoringTeamList.find(item => item.value === monitoringId)
       if (team === undefined) return ''
       return this.$i18n.locale === 'bn' ? team.text_bn : team.text_en
     },
     getFiscalYear (fisId) {
      const fisYear = this.$store.state.commonObj.fiscalYearList.find(item => item.value === fisId)
      if (fisYear === undefined) return ''
      return this.$i18n.locale === 'bn' ? fisYear.text_bn : fisYear.text_en
     },
      getOrgProfileList (orgId) {
      const orgName = this.$store.state.commonObj.organizationProfileList.find(item => item.value === orgId)
      if (orgName === undefined) return ''
      return this.$i18n.locale === 'bn' ? orgName.text_bn : orgName.text_en
     },
      getDesignationName (designationId) {
       const designation = this.$store.state.commonObj.designationList.find(item => item.value === designationId)
       if (designation === undefined) return ''
       return this.$i18n.locale === 'bn' ? designation.text_bn : designation.text_en
     },
     getRoleName (roleId) {
       const role = this.$store.state.AgriResearch.commonObj.roleInformationList.find(item => item.value === roleId)
       if (role === undefined) return ''
       return this.$i18n.locale === 'bn' ? role.text_bn : role.text_en
     },
      pdfExport () {
      const reportTitle = this.$t('research_manage.team_member') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(reportTitle, this.teamMembers, this.teamMembers.members, this)
    }
  }
}
</script>
