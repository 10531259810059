import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import { mobileNumber } from '@/Utils/fliter'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (reportTitle, teamMembers, invitationDetails, vm) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const pdfContent = []
      pdfContent.push({ text: reportTitle, style: 'hh', alignment: 'center' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['20%', '*', '*', '*'],
              body: [
                [
                  { text: vm.$t('research_manage.team_name'), style: 'th', alignment: 'left' },
                  { text: vm.getMonitoringTeam(teamMembers.team_id), style: 'td', alignment: 'left' },
                  { text: vm.$t('globalTrans.fiscal_year'), style: 'th', alignment: 'left' },
                  { text: vm.getFiscalYear(teamMembers.fiscal_year_id), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('research_manage.organization_name'), style: 'th', alignment: 'left' },
                  { text: vm.getOrgProfileList(teamMembers.org_id), style: 'td', alignment: 'left', colSpan: '3' },
                  { },
                  { }
                ]
              ]
            }
          }
        )
        // option
          let allRows = []
          allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'center' },
              { text: vm.$t('research_manage.designation_en'), style: 'th', alignment: 'center' },
              { text: vm.$t('research_manage.team_member'), style: 'th', alignment: 'center' },
              { text: vm.$t('research_manage.role_name'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalUserData.mobile_no'), style: 'th', alignment: 'center' }
            ]
          ]
          // table body
          teamMembers.members.map((item, index) => {
            allRows.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: vm.getOrgName(item.org_id), style: 'td', alignment: 'center' },
              { text: vm.getDesignationName(item.designation_id), style: 'td', alignment: 'center' },
              { text: vm.getTeamMember(item.user_id), style: 'td', alignment: 'center' },
              { text: vm.getRoleName(item.master_role_id), style: 'td', alignment: 'center' },
              { text: mobileNumber(item.mobile_no), style: 'td', alignment: 'center' }
            ])
          })
          pdfContent.push({ text: '', style: 'tableMargin' })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['9%', '*', '*', '*', '*', '*'],
              body: allRows
            }
          })

        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            th1: {
                fontSize: 9
              },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            search: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            hh: {
                fontSize: 14,
                bold: true,
                margin: [10, 10, 25, 20]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            krishi: {
              margin: [0, 1, 0, 15],
              alignment: 'center'
            },
            header3: {
              fontSize: 9,
              margin: [0, 15, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        // pdfMake.createPdf(docDefinition, null, null, null).download('proposal-invitation-details')
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
