<template>
  <b-container fluid>
    <b-overlay :show="formLoader">

      <!-- form -->
      <ValidationObserver ref="form">
        <b-form>
          <b-row>
            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Team" vid="team_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="team_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('research_manage.team_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="teamMembers.team_id"
                        :options="monitoringTeamList"
                        id="team_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Organization" vid="org_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="org_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('research_manage.organization_name')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="teamMembers.org_id"
                        :options="organizationProfileList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('globalTrans.fiscal_year')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="teamMembers.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>

      <!-- form1 -->
      <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
          <b-row>

            <b-col sm="12" md="12" lg="12" xl="12">
              <div style="font-size:18px; background-color: #386389;">
                  <h5 class="text-white text-center">
                      {{ `${$t('research_manage.team_member')} ${$t('globalTrans.add')}` }}
                  </h5>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Organization" vid="form_data_org_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="form_data_org_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{ $t('research_manage.organization_name') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.org_id"
                        :options="organizationProfileList"
                        id="form_data_org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Designation" vid="form_data_designation_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="form_data_designation_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{ $t('research_manage.designation') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.designation_id"
                        :options="designationListByOrganization"
                        id="form_data_designation_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Team Member" vid="form_data_user_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="form_data_user_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{ $t('research_manage.team_member') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.user_id"
                        :options="userListByDesignation"
                        id="form_data_user_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Role" vid="form_data_master_role_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="form_data_master_role_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{ $t('research_manage.role_name') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.master_role_id"
                        :options="roleInformationList"
                        id="form_data_master_role_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="4" xl="4">
              <ValidationProvider name="Mobile No" vid="form_data_master_mobile_no" rules="required|numeric">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="form_data_master_mobile_no"
                  slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                      {{ $t('globalUserData.mobile_no') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    class="form-control"
                    v-model="formData.mobile_no"
                    id="form_data_master_mobile_no"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :disabled="true"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="12" lg="12" xl="12">
              <div class="col text-right">
                <b-button type="submit" variant="primary" class="mr-1">{{ $t('globalTrans.add') }}</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <hr>
      </ValidationObserver>

       <!-- form2 -->
      <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-table-simple bordered>
                <thead class="text-white bg-primary">
                  <tr>
                    <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                    <th class="text-center">{{ $t('globalTrans.organization' )}}</th>
                    <th class="text-center">{{ $t('research_manage.designation_en') }}</th>
                    <th class="text-center">{{ $t('research_manage.team_member') }}</th>
                    <th class="text-center">{{ $t('research_manage.role_name') }}</th>
                    <th class="text-center">{{ $t('globalUserData.mobile_no') }}</th>
                    <th class="text-center">{{ $t('globalTrans.action') }}</th>
                  </tr>
                </thead>
                <b-tbody>
                  <b-tr v-for="(item, index) in teamMembers.members" :key="item.user_id">
                      <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                      <b-td class="text-center">{{ getOrgName(item.org_id) }}</b-td>
                      <b-td class="text-center">{{ getDesignationName(item.designation_id) }}</b-td>
                      <b-td class="text-center">{{ getUserFromPrimaryUserList(item.user_id) }}</b-td>
                      <b-td class="text-center">{{ getRoleName(item.master_role_id) }}</b-td>
                      <b-td class="text-center">{{ item.mobile_no }}</b-td>
                      <b-td class="text-center">
                        <b-button type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm">
                          <i class="fa fa-trash m-0"></i>
                        </b-button>
                      </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>

            <b-col sm="12" md="12" lg="12" xl="12">
              <div class="col text-right">
                <b-button type="submit" variant="primary" class="mr-1" :disabled="isSaveButtonDisabled">{{ saveBtnName }}</b-button>
                <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>

    </b-overlay>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { usersByDesignationApi, monitoringTeamMembersStoreApi, monitoringTeamMembersShowApi, monitoringTeamMembersUpdateApi } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    if (this.id) {
      const tmp = this.getDataFromStore()
      if (tmp) {
        this.formLoader = true
        const result = await RestApi.getData(agriResearchServiceBaseUrl, `${monitoringTeamMembersShowApi}/${this.id}`)
        if (result.success) {
          this.primaryUserList = result.data.user_details
          this.teamMembers = tmp
        }
        this.formLoader = false
      }
    }
  },
  mounted () {
  },
  data () {
    return {
      formLoader: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      teamMembers: {
        id: 0,
        team_id: 0,
        org_id: 0,
        fiscal_year_id: 0,
        members: []
      },
      formData: {
        org_id: 0,
        designation_id: 0,
        user_id: 0,
        master_role_id: 0,
        mobile_no: ''
      },
      designationListByOrganization: [],
      userListByDesignation: [],
      primaryUserList: []
    }
  },
  computed: {
    monitoringTeamList: function () {
      return this.$store.state.AgriResearch.commonObj.monitoringTeamList.filter(item => item.status === 1)
    },
    roleInformationList: function () {
      return this.$store.state.AgriResearch.commonObj.roleInformationList
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList
    },
    organizationProfileList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    currentLocale () {
      return this.$i18n.locale
    },
    isSaveButtonDisabled () {
      return this.teamMembers.members.length <= 0
    }
  },
  watch: {
    'teamMembers.team_id': function (newVal, oldVal) {
      if (newVal > 0) {
        const monitoringTeam = this.monitoringTeamList.find(item => item.value === newVal)
        this.teamMembers.org_id = monitoringTeam.org_id
        this.teamMembers.fiscal_year_id = monitoringTeam.fiscal_year_id
      } else {
        this.teamMembers.org_id = 0
        this.teamMembers.fiscal_year_id = 0
      }
    },
    'formData.org_id': function (newVal, oldVal) {
      this.formData.designation_id = 0
      if (newVal > 0) {
        this.getDesignationListByOrganization(newVal)
      } else {
        this.designationListByOrganization = []
      }
    },
    'formData.designation_id': function (newVal, oldVal) {
      this.formData.user_id = 0
      if (newVal > 0) {
        this.getUserList(newVal)
      } else {
        this.userListByDesignation = []
      }
    },
    'formData.user_id': function (newVal, oldVal) {
      if (newVal > 0) {
        const user = this.userListByDesignation.find(item => item.user_id === newVal)
        this.formData.mobile_no = user !== undefined ? user.phone_no : ''
      } else {
        this.formData.mobile_no = ''
      }
    }
  },
  methods: {
    getDesignationListByOrganization (orgId) {
      this.designationListByOrganization = this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === orgId)
    },
    getDataFromStore () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return tmpData !== undefined ? JSON.parse(JSON.stringify(tmpData)) : undefined
    },
    addItem () {
      /** Check Unique Team Members */
      const member = this.teamMembers.members.find(item => item.user_id === this.formData.user_id)

      if (member === undefined) {
        const user = this.userListByDesignation.find(item => item.user_id === this.formData.user_id)
        this.primaryUserList.push(this.generatePrimaryUserObject(user))
        this.teamMembers.members.push(Object.assign({}, this.formData))
        this.resetFormData()
      } else {
        const user = this.userListByDesignation.find(item => item.user_id === this.formData.user_id)
        window.vm.$swal({
          icon: 'error',
          title: 'Duplicate Member',
          text: `${this.currentLocale === 'en' ? user.name : user.name_bn}`
        })
        this.resetFormData()
      }
    },
    resetFormData () {
      /** Watch Handling Rest of the Reset */
      this.formData.user_id = 0
      this.formData.master_role_id = 0

      /** Reset Form */
      this.$nextTick(() => {
        this.$refs.form1.reset()
      })
    },
    deleteItem (index) {
      this.primaryUserList.splice(index, 1)
      this.teamMembers.members.splice(index, 1)
    },
    generatePrimaryUserObject (user) {
      /**
       * Matching this list to be identical to the one returned by Back-End
       * During Update & View Details
       */
      if (user !== undefined) {
        return {
          value: user.user_id,
          designation_id: user.designation_id,
          text_en: user.name,
          text_bn: user.name_bn,
          username: '',
          office_id: user.designation_id
        }
      }
    },
    /**
     * To be used in List
     */
     getOrgName (orgId) {
      const orgName = this.$store.state.orgList.find(item => item.value === orgId)
      if (orgName === undefined) return ''
      return this.$i18n.locale === 'bn' ? orgName.text_bn : orgName.text_en
     },
     getDesignationName (designationId) {
       const designation = this.$store.state.commonObj.designationList.find(item => item.value === designationId)
       if (designation === undefined) return ''
       return this.$i18n.locale === 'bn' ? designation.text_bn : designation.text_en
     },
     getRoleName (roleId) {
       const role = this.$store.state.AgriResearch.commonObj.roleInformationList.find(item => item.value === roleId)
       if (role === undefined) return ''
       return this.$i18n.locale === 'bn' ? role.text_bn : role.text_en
     },
     getUserFromPrimaryUserList (userId) {
       const user = this.primaryUserList.find(item => item.value === userId)
       if (user === undefined) return ''
       return this.$i18n.locale === 'bn' ? user.text_bn : user.text_en
     },
    /**
     * Asynchronous
     */
    async getUserList () {
      this.userListByDesignation = []
      if (this.formData.org_id <= 0 && this.formData.designation_id <= 0) return false

      this.formLoader = true
      const params = { org_id: this.formData.org_id, designation_id: this.formData.designation_id }
      const result = await RestApi.getData(authServiceBaseUrl, usersByDesignationApi, params)

      if (result.success) {
        this.userListByDesignation = result.data.map(item => {
          if (this.currentLocale === 'en') {
            return { ...item, value: item.user_id, text: item.name }
          } else {
            return { ...item, value: item.user_id, text: item.name_bn }
          }
        })
      }
      this.formLoader = false
    },
    async saveData () {
      const validateForm = await this.$refs.form.validate()
      if (!validateForm) return false

      this.formLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null

      if (this.teamMembers.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${monitoringTeamMembersUpdateApi}/${this.id}`, this.teamMembers)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, monitoringTeamMembersStoreApi, this.teamMembers)
      }

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })

        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })

        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }

      this.formLoader = false
    }
  }
}
</script>
