<template>
  <b-container fluid>
    <!-- Search Form -->
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">
          {{ $t('research_manage.monitoring_team_member_info') }}
        </h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('research_manage.team_name')"
              label-for="team_id"
            >
              <b-form-select
                plain
                v-model="search.team_id"
                :options="monitoringTeamList"
                id="team_id"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <!-- /Search Form -->

    <b-row>
      <b-col md="12">
        <iq-card>

          <!-- headerTitle -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.monitoring_team_member_info') }} {{ $t('globalTrans.list') }}</h4>
          </template>

          <!-- headerAction -->
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>

          <!-- body -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                    <!-- Table -->
                  <b-table bordered hover :items="listData" :fields="columns"  aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>

                    <!-- Active/Inactive -->
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-danger" v-if="data.item.status === 2">{{$t('globalTrans.inactive')}}</span>
                        <span class="badge badge-success" v-else>{{$t('globalTrans.active')}}</span>
                    </template>

                    <template v-slot:cell(action)="data">
                      <!-- Modal 1 -->
                      <b-button class="mr-1" title="View Details" v-b-modal.modal-detail variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" title="Edit" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <!-- Change Status | Toggle Status-->
                      <b-button :variant="data.item.status === 2 ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" title="Active/Deactive" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status === 2 ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>

        </iq-card>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-detail" size="xl" :title="`${$t('research_manage.team_member')} ${$t('globalTrans.details')}`" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>

import Form from './Form'
import DetailModal from './DetailModal.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringTeamMembersListApi, monitoringTeamMembersToggleStatusApi } from '@/modules/agri-research/research-management/api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, DetailModal
  },
  data () {
    return {
      editItemId: 0,
      search: {
        team_id: 0
      }
    }
  },
  computed: {
    monitoringTeamList: function () {
      return this.$store.state.AgriResearch.commonObj.monitoringTeamList.filter(item => item.status === 1)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_manage.monitoring_team_member_info') + ' ' + this.$t('globalTrans.entry')
        : this.$t('research_manage.monitoring_team_member_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('research_manage.team_name'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('external_research.fiscal_year'), class: 'text-center' },
          { label: this.$t('research_manage.no_of_members'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'team_bn' },
          { key: 'org_bn' },
          { key: 'fiscal_year_bn' },
          { key: 'no_of_members' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'team_en' },
          { key: 'org_en' },
          { key: 'fiscal_year_en' },
          { key: 'no_of_members' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
     details (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, monitoringTeamMembersToggleStatusApi, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, monitoringTeamMembersListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        // Relational
        const team = this.$store.state.AgriResearch.commonObj.monitoringTeamList.find(team => team.value === item.team_id)
        const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.org_id)
        const fiscalYear = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)

        // Evaluate
        const evaluationData = {
          team_en: team !== undefined ? team.text_en : '',
          team_bn: team !== undefined ? team.text_bn : '',
          org_en: org !== undefined ? org.text_en : '',
          org_bn: org !== undefined ? org.text_bn : '',
          fiscal_year_en: fiscalYear !== undefined ? fiscalYear.text_en : '',
          fiscal_year_bn: fiscalYear !== undefined ? fiscalYear.text_bn : '',
          no_of_members: item.members.length
        }

        // Assign
        return Object.assign({}, item, evaluationData)
      })
      return listData
    }
  }
}
</script>
